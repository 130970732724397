
import { observable } from 'mobx'
import config from '../config/config'

class ShopsStore {
  @observable items = []
  @observable columns = []  

  constHeader = [
    { key: 'shop_name', text : 'UNITREE_SHOP_NAME' },
    { key: 'languages', text: 'SHOP_LANGUAGES' },
    { key: 'shops', text: 'SHOP_SHOPS' },
    { key: 'buttons', text: '' }
  ]

  @observable languagesColumns = []  
  constLanguagesHeader = [
    { key: 'language', text : 'UNITREE_LANGUAGE_ISO' },
    { key: 'buttons', text: '' }
  ]
  @observable languages = []

  @observable languagesIso = []
  langs = ['de', 'it', 'fr', 'en', 'hu', 'nl', 'ro', 'cs', 'sr', 'bg', 'el', 'es', 'hr', 'pt', 'ru', 'sk', 'sl', 'uk' ]

  @observable shopsColumns = []  
  constShopsHeader = [
    { key: 'shop', text : 'UNITREE_SHOP_NAME' },
    { key: 'buttons', text: '' }
  ]
  @observable shops = []

  @observable shopNames = []
  shopsValues = ['dch', 'tm', 'wbb', 'mch', 'fcar', 'ax-cz', 'wt-sb', 'mat', 'dat', "aro", "ahu", "asl", "ax-hu" ]

  constructor (authStore, unitreeStore) {
    this.authStore = authStore 
    this.unitreeStore = unitreeStore
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: '20%',
      minWidth: 120,
      maxWidth: 200,
    })
    )
    this.constLanguagesHeader.forEach(({key}) => this.languagesColumns.push({
        key,
        fieldName: key,
        isResizable: true,
        width: '50%',
        minWidth: 120,
        maxWidth: 200,
      })
    )
    this.constShopsHeader.forEach(({key}) => this.shopsColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: '50%',
      minWidth: 120,
      maxWidth: 200,
    })
  )
    // set dropdown options
    this.langs.forEach(lang => this.languagesIso.push({
        key: lang,
        text: lang
        })
    )
    // set dropdown options
    this.shopsValues.forEach(shop => this.shopNames.push({
      key: shop,
      text: shop
    })
  )
  }

  fetchShops = async () => {
    const result = await fetch(`${config.server_address}/fe/shops`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    this.items = data.message
  }

  /**
   * Remove a shop from the DB
   */
  removeShop = async (key) => {
    // remove from DB 
    const item = this.items[key]
    const result = await fetch(`${config.server_address}/fe/shops/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Shop ${item.shop_name} successfully deleted !`)
      await this.fetchShops()
    }
  }

  editShop = async (editItem) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/shops/${editItem.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: editItem.id,
        shop_name: editItem.shop_name, 
        languages: editItem.languages, 
        shops: editItem.shops
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Shop ${editItem.shop_name} ${editItem.id === undefined ? 'successfully inserted' : 'successfully updated'}!`)
      await this.fetchShops()
    }
  }

  handleLanguagesDialog = (item) => {
    this.languages = []
    if (item !== undefined && item.languages !== undefined && item.languages !== null) {
        const languagesArr = item.languages.split(',')
        languagesArr.forEach(lang => {
            const obj = {language : lang }
            this.languages.push(obj)
        })
    }
  }

  removeLanguage = (languageDelIndex) => {
    this.languages.splice(languageDelIndex, 1)
  }

  insertLanguage = (languageItem) => {
    this.languages.push(languageItem)
  }

  handleShopsDialog = (item) => {
    this.shops = []
    if (item !== undefined && item.shops !== undefined && item.shops !== null) {
        const shopsArr = item.shops.split(',')
        shopsArr.forEach(shop => {
            const obj = { shop }
            this.shops.push(obj)
        })
    }
  }

  removeShopShops = (shopDelIndex) => {
    this.shops.splice(shopDelIndex, 1)
  }

  insertShop = (shopItem) => {
    this.shops.push(shopItem)
  }
}
export default ShopsStore
