
import { observable, action } from 'mobx'
import config from '../config/config'

class ESObjectStore {
  @observable columns = []  

  @observable shops = []
  @observable langs = []
  @observable shopShops = []
  mapShops = new Map() 
  
  defaultLang
  
  @observable genarts = undefined
  @observable criterias = undefined
  @observable suppliers = undefined
  @observable artGrps = undefined
  @observable brands = undefined
  @observable manufacturers = undefined

  mapCriterias = new Map()
  mapGenarts = new Map()
  mapSuppliers = new Map()
  mapArtGrps = new Map()
  mapManufacturers = new Map()

  @observable envs = []

  environments = {
    //local: 'local PC',
    preProd: 'preProd', 
    prod: 'prod-CH-AT-IT',
    prodCZ: 'prod-CZ-RS-RO',
    prodHU: 'prod-HU' 
  }

  constHeader = [
    { key: 'shop_name', text : 'UNITREE_SHOP_NAME' },
    { key: 'env_txt', text : 'UNITREE_ENV' },
    { key: 'version_name', text: 'UNITREE_VERSION_NUMBER' },
    { key: 'created_at', text: 'UNITREE_CREATED_AT' },
    { key: 'user', text: 'UNITREE_USER' },
    { key: 'buttons', text: '' }
  ]
  constructor (authStore) {
    this.authStore = authStore 
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: '15%',
      minWidth: 120,
      maxWidth: 200,
    })
    )
  }

  initialiseData = (shop, appLang) => {
    if (shop === undefined) {
      this.defaultLang = appLang
      return
    }
    this.shopShops = shop.shops.split(",")
    this.langs = shop.languages.split(",")
    if (this.langs.includes(appLang)) {
      this.defaultLang = appLang
    } else {
      this.defaultLang = this.langs[0]
    }
  }

  fetchShops = async () => {
    this.mapShops.clear()
    this.shops = []
    const result = await fetch(`${config.server_address}/fe/shops`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      data.message.forEach(shop => {
        if (this.authStore.hasUserRight(shop.shop_name) === true) {
          this.shops.push({
            key: shop.id,
            text: shop.shop_name
          })
        }
        this.mapShops.set(shop.id, shop)
      })
    }
  }

  fetchEnvs = async () => {
    const result = await fetch(`${config.server_address}/fe/unitree/env/es`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      Object.keys(data.message).forEach(env => {
        this.envs.push({
          key: env,
          text: this.environments[env]
        })
      })
    }
  }
  /**
   * GESObjects from DB
   */
   populateESObjects = async (index_type, reloadShops = false) => {
    if (this.envs.length === 0) {
      await this.fetchEnvs()
    }
    if (reloadShops || this.mapShops.size === 0) {
      await this.fetchShops()
    }
    const result = await fetch(`${config.server_address}/fe/unitree/${index_type}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    return data.message
  }

  /**
   * Remove a unitree from the DB and elastic search
   */
  removeESObject = async (item) => {
    // remove from DB 
    const result = await fetch(`${config.server_address}/fe/unitree/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`${item.shop_name}_${item.index_type}_${item.version_name} successfully deleted !`)
    }
  }

  editESObject = async (editItem) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/unitree/${editItem.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: editItem.id,
        shop_id: editItem.shop_id, 
        env: editItem.env, 
        version_name: editItem.version_name, 
        user: this.authStore.current_user.DisplayName,
        index_type: editItem.index_type
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`${editItem.shop_name}_${editItem.index_type}_${editItem.version_name} ${editItem.id === undefined ? 'successfully inserted' : 'successfully updated'}!`)
    }
  }

  exportEsObject = async (unitreeItem) => {
    const result = await fetch(`${config.server_address}/fe/exporter/export/${unitreeItem.id}/${unitreeItem.index_type}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return false
    }
    return result.status === 200
  }

  /**
   * Trigger a github action that refreshes the cache of the connect webshop
   * @param shop - shop_name - depending on shop_name identify the country: AT, CH, CZ, RS, IT or AUTONET
   * @param environment production or pre-production
  */
  runGitHubAction = async(shop, environment) => {
    console.log('run github action to trigger connect refresh cache')
    const env = environment.startsWith('prod') ? 'production' : 'pre-production'
    let country
    if (shop.toLowerCase().includes('at')) {
      country = 'AT'
    } else if (shop.toLowerCase().includes('ch')) {
      country = 'CH'
    } else if (shop.toLowerCase().includes('cz')) {
      country = 'CZ'
    } else if (shop.toLowerCase().includes('rs')) {
      country = 'RS'
    }  else if (shop.toLowerCase().includes('it')) {
      country = 'IT'
    } else if (shop.toLowerCase().includes('ro')) {
      country = 'RO'
    } else if (shop.toLowerCase().includes('hu')) {
      country = 'HU'
    }
    if (country === undefined) {
      return
    }
    const result = await fetch(`${config.server_address}/fe/exporter/runGithubAction/${country}/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }

  /**
   * Get array of genarts from ES based on the current language
   */
  @action
  fetchGenarts = async (env) => {
    const promises = []
    for (const lang of this.langs) {
      promises.push(this.fetchGenartsByLang(lang, env))
    }
    await Promise.all(promises)
  }

  fetchGenartsByLang = async (lang, env) => {
    const result = await fetch(`${config.server_address}/fe/servo_data/genart/${lang}/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      // populate mapGenarts
      const map = new Map()
      if (data.success) {
        data.message.map(genart => {
          map.set(genart.id, genart.text)
        })
        this.mapGenarts.set(lang, map)
        // populate genarts for comboOptions
        if (lang === this.defaultLang) {
          this.genarts = data.message.map(genart => {
            return ({
              key: genart.id,
              value: genart.id,
              text: genart.id + "-" + genart.text
            })
          })
        }
      }
    }
  }

  /**
   * Get criteria in all languages
   */
  @action
  fetchCriterias = async (env) => {
    const promises = []
    for (const lang of this.langs) {
      promises.push(this.fetchCriteriasByLang(lang, env))
    }
    await Promise.all(promises)
  }
  /**
   * Get array of criterias from ES based on the current language
   */
  @action
  fetchCriteriasByLang = async (lang, env) => {
    const result = await fetch(`${config.server_address}/fe/servo_data/criteria/${lang}/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      // populate mapCriterias
      const map = new Map()
      data.message.map(criteria => {
        map.set(criteria.id, criteria.text)
      })
      this.mapCriterias.set(lang, map)
      // populate genarts for comboOptions
      if (lang === this.defaultLang) {
        this.criterias = data.message.map(criteria => {
          return ({
            key: criteria.id,
            value: criteria.id,
            text: criteria.id + "-" + criteria.text
          })
        })
      }
    }
  }

  /**
   * Get array of suppliers from ES 
   */
  @action
  fetchSuppliers = async (env) => {
    const result = await fetch(`${config.server_address}/fe/servo_data/suppliers/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      this.suppliers = data.message.map(supplier => {
        this.mapSuppliers.set(supplier.id, supplier.text)
        return ({
          key: supplier.id,
          value: supplier.id,
          text: supplier.id + "-" + supplier.text
        })
      }
      )
    }
  }

  /**
   * Get brands from db
   */
  @action
  fetchBrands = async () => {
    const result = await fetch(`${config.server_address}/fe/categories/servo/brands`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      this.brands = data.message.map(brand => {
        return ({
          key: brand.id_brand,
          value: brand.id_brand,
          text: brand.id_brand + "-" + brand.brandname
        })
      }
      )
    }
  }

  /**
   * Get manufacturers from db
   */
  @action
  fetchManufacturers = async () => {
    const result = await fetch(`${config.server_address}/fe/brandPriorities/servo/manufacturers`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      this.manufacturers = data.message.map(man => {
        this.mapManufacturers.set(man.id_manufacturer, man.name)
        return ({
          key: man.id_manufacturer,
          value: man.id_manufacturer,
          text: man.id_manufacturer + "-" + man.name
        })
      }
      )
    }
  }

  /**
   * Get array of article groups from ES based on the current language
   */
  @action
  fetchArtgrps = async (shop, lang, env) => {
    if (shop.indexOf('_') > 0) {
      shop = shop.substring(shop.indexOf('_') + 1)
    }
    const result = await fetch(`${config.server_address}/fe/servo_data/artgrp/${shop}/${lang}/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      this.artGrps = data.message.map(artgrp => {
        this.mapArtGrps.set(artgrp.id, artgrp.text)
        return ({
          key: artgrp.id,
          value: artgrp.id,
          text: artgrp.id + "-" + artgrp.text
        })
      })
    }
  }

  getIndexAliases = async (item) => {
    const shop = this.mapShops.get(item.shop_id)
    if (shop !== undefined) { 
      const index_name = shop.shop_name + '_' + item.index_type + '_' + item.version_name
      const result = await fetch(`${config.server_address}/fe/exporter/alias/${item.env}/${index_name}/${shop.languages}/${item.index_type}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      if (result.status !== 200) {
        return []
      }
      const res = await result.json()
      return res.message
    }
  }

  setAliases = async (env, actions) => {
    if (actions.length > 0) { 
      const result = await fetch(`${config.server_address}/fe/exporter/setAlias`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          env,
          actions
        })
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
    }
  }
}

export default ESObjectStore
