import React, { Component } from 'react'
import { Label, Dialog, DialogType, DialogFooter, TextField, Stack, PrimaryButton, DefaultButton, Dropdown } from '@fluentui/react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import TilesExternal from './TilesExternal'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'

@inject('store')
@withTranslation(['common'])
@observer
class DialogNode extends Component {
  constructor (props) {
    super(props)
    this.tileStore = this.props.store.tileStore
    this.unitreeStore = this.props.store.unitreeStore
    this.esObjectStore = this.props.store.esObjectStore
  }

  handleClose = () => {
    this.tileStore.handleShowDialog()
    const { origEditTile, editIndex } = this.tileStore
    if (editIndex !== undefined) {
      this.tileStore.tiles[editIndex] = JSON.parse(origEditTile)
    }
    this.tileStore.editIndex = undefined,
    this.tileStore.origEditTile = ''
  }

  handleChange = (key, value) => {
    const editTile = this.tileStore.editTile
    if (editTile !== undefined) {
        editTile[key] = value
    }
  }

  handleSave = () => {
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.tileStore.editTile.tile_name_trans[lang] === '' || this.tileStore.editTile.tile_name_trans[lang] === undefined) {
        empty = true
      }
    })
    if (empty) {
      return
    }
    this.tileStore.saveTile().then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.tileStore.tileDB.tree_id))
  }

  render () {
    const { t, store: { tileStore: { showDialog, editTile, tileTypes },
      esObjectStore: { langs }, unitreeStore : { nodesOptions } } } = this.props
    const stackTokens = { childrenGap: 10 }
    const growingStyles = {
        root: {
          display: 'flex',
          height: '50%',
          width: 600,
        }
      }
    const dialogTextFieldsStyles = {
      fieldGroup: { width: 280, height: 20, float: 'right' }
    }
    return (
      <div>
        <Dialog
          minWidth={1200}
          maxWidth={1250}
          hidden={!showDialog}
          onDismiss={this.handleClose}
          dialogContentProps={{
            type: DialogType.normal,
            title: editTile !== undefined && editTile.isEdit ? t('common:TILE_EDIT') : t('common:TILE_ADD')
          }}
          modalProps={{ isBlocking: true, dragOptions: true,
            styles: { main: { maxHeight: 600 } } }}
        >
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%'}}}>
              <Stack horizontal tokens={stackTokens}>
                <Stack.Item styles={{root: {width: '20%'}}}>
                  <Label>{t('common:TILE_NODE')}</Label>
                </Stack.Item>
                <Stack.Item styles={{root: {width: '80%'}}}>
                  <LookupDropdown
                    placeholder={t('common:SELECT_TILE_LINK')}
                    defaultValue={editTile !== undefined ? editTile.tile_node_id : ''}
                    fluid
                    search
                    selection
                    closeOnChange
                    clearable
                    options={nodesOptions}
                    onChange={(e, { value }) => {
                      editTile.tile_node_id = value
                      const node = this.unitreeStore.nodesOptions.find(option => option.value === value)
                      editTile.tile_node = node !== undefined ? node.text : ''
                    }}
                  />
                </Stack.Item>  
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Stack.Item styles={{root: {width: '20%'}}}>
                  <Label>{t('common:TILE_TARGET_TREE')}</Label>
                </Stack.Item>
                <Stack.Item styles={{root: {width: '80%'}}}>
                  <LookupDropdown
                    placeholder={t('common:SELECT_TILE_LINK')}
                    defaultValue={editTile !== undefined ? editTile.tile_target_tree : ''}
                    fluid
                    search
                    selection
                    closeOnChange
                    clearable
                    options={nodesOptions}
                    onChange={(e, { value }) => {
                      editTile.tile_target_tree = value
                    }}
                  />
                </Stack.Item>  
              </Stack>   
              <Stack horizontal tokens={stackTokens}>
                <Stack.Item styles={{root: {width: '20%'}}}>
                  <Label>{t('common:TILE_NODE_LINK')}</Label>
                </Stack.Item>
                <Stack.Item styles={{root: {width: '80%'}}}>
                  <LookupDropdown
                    placeholder={t('common:SELECT_TILE_LINK')}
                    defaultValue={editTile !== undefined ? editTile.tile_node_link : ''}
                    fluid
                    search
                    selection
                    closeOnChange
                    clearable
                    options={nodesOptions}
                    onChange={(e, { value }) => {
                      editTile.tile_node_link = value
                    }}
                  />
                </Stack.Item>  
              </Stack>
              {langs.map(lang => {
                return (
                <TextField label={t('common:TREE_NAME')+ ' ' + lang} id={'tile_name_'+ lang} key={'tile_name_'+ lang} 
                  defaultValue={editTile !== undefined && editTile.tile_name_trans !== undefined? editTile.tile_name_trans[lang] : ''} 
                  required type='text' onGetErrorMessage={ value => { if (value === '') return lang + t('common:TREE_NAME_DIALOG_INPUT_ERR_MSG') }} 
                  onChange={(event, newValue) => {
                    if (editTile !== undefined) {
                      editTile.tile_name_trans[lang] = newValue
                    }
                  }
                  } styles={dialogTextFieldsStyles}/>
                )})}
              <Dropdown id='tile_type'
                label={t('common:TILE_TYPE')}
                defaultSelectedKey={editTile !== undefined ? editTile.tile_type : ''}
                options={tileTypes}
                onChange={(event, { key }) => this.handleChange('tile_type', key !== '' ? key : null)}
                styles={{dropdown: { width: 280, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
              />
              <TextField label={t('common:TREE_IMAGE')} id='tile_image' defaultValue={editTile !== undefined ? editTile.tile_image : ''} 
                onChange={(event, newValue) => this.handleChange('tile_image', newValue)} styles={dialogTextFieldsStyles}/>   
          </Stack>
        </Stack.Item>  
        <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
            <div id='tilesExternal'><TilesExternal /></div>
        </Stack.Item>
        </Stack>  
          <DialogFooter>
            <PrimaryButton onClick={this.handleSave} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleClose} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}
export default DialogNode
