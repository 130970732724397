import { observable, action } from 'mobx'
import config from '../../config/config'
const shops = require('../../config/shops.json')
class ArticleStore {

  @observable columns = []
  @observable items = []
  @observable esItemsCount = ''
  @observable servoItemsCount = ''
  
  @observable columnsDetails = []
  @observable detailsItems = []
  
  @observable searchText = ''
  
  @observable searchByArtNr = false 

  @observable checked = []

  universalPartsGenarts = ['1831', '12488', '1852', '6072', '2632', '3536', '4680', '2489', '2501', '5188', '4707'] 

  chShopsMapping = {
    ch: { shop: 'ch', mappingTable: 'SAG_AxArticleData' },
    at: { shop: 'ax_at', mappingTable: 'SAG_AxArticleData' },
    cz: { shop: 'ax_cz', mappingTable: 'SAG_AxArticleData' },
    hu: { shop: 'ax_hu', mappingTable: 'SAG_AxArticleData'},
    it: { shop: 'ms_it', mappingTable: 'ArticleMapping_FCAR' },
    sr: { shop: 'rs', mappingTable: 'ArticleMapping_WINT' },
    ro: { shop: 'ro', mappingTable: 'ArticleMapping_Autonet' }
  }

  itemEs = {}

  @observable item = {}

  mapWisch = new Map()

  mapGenarts = new Map()
  
  mapServoGenarts = new Map()

  mapTextModues =  new Map()

  excludeOELocksAT = []

  results = new Map()
  
  countries = []

  langsByCountry = new Map()
  
  defaultLang

  COUNTRY_TO_INSTANCES = {
		at: ['dat', 'mat', 'sag-gh-at'], 
    ch: ['dch', 'wbb', 'tm', 'mch'], 
    cz: ['ax-cz'],
    hu: ['ax-hu']
	}

  constHeader = [
    { name: 'Article ID', key: 'id_art' }, 
    { name: 'Article number', key: 'art_nr' },
    { name: 'Manufacturer name', key: 'manufacturer' }, 
    { name: 'Genart', key: 'id_genart' }, 
    { name: 'Country', key: 'cty' }  
  ]

  constDetailHeader = [
    { name: '', key: 'detail', width: '20%', minWidth: 150, maxWidth: 150 }, 
    { name: 'Servo', key: 'servo', width: '40%', minWidth: 400, maxWidth: 400 }, 
    { name: 'Connect data', key: 'es', width: '40%', minWidth: 400, maxWidth: 400 }  
  ]

  constructor (authStore, appStore) {
    this.authStore = authStore 
    this.appStore = appStore
    this.constHeader.forEach(header => this.columns.push({
      key: header.key,
      name: header.name,
      fieldName: header.key,
      isResizable: true,
      width: '20%',
      minWidth: 100,
      maxWidth: 200 })
    )
    this.constDetailHeader.forEach(header => this.columnsDetails.push({
      key: header.key,
      name: header.name,
      fieldName: header.key,
      isResizable: false,
      width: header.width,
      minWidth: header.minWidth,
      maxWidth: header.maxWidth
     })
    )
  }

  // Add/Remove checked item from list
  handleCheck = (event, newValue) => {
    var updatedList = [...this.checked];
    if (newValue) {
      updatedList = [...this.checked, event.target.id]
    } else {
      updatedList.splice(this.checked.indexOf(event.target.id), 1)
    }
    this.checked  = updatedList;
  }

  resetFields = () => {
    this.items = []
    this.searchText = ''
    this.servoItemsCount = ''
  }

  /**
   * Get array of genarts from ES based on the current language
   */
  @action
  fetchGenarts = async (env, cty) => {
    const promises = []
    const langs = shops[this.chShopsMapping[cty].shop].languages.split(',')
    for (const lang of langs) {
      promises.push(this.fetchGenartsByLang(env, cty, lang))
    }
    await Promise.all(promises)
  }
  fetchGenartsByLang = async (env, cty, lang) => {
    const result = await fetch(`${config.server_address}/fe/es/genart/${env}/${shops[this.chShopsMapping[cty].shop].esServer}/${lang}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    // populate mapGenarts
    if (data.success) {
      const map = new Map()
      data.message.map(genart => {
        map.set(genart.id, genart.text)
      })
      this.mapGenarts.set(lang, map)    
    }
  }

  loadWischFile = async() => {
    this.mapWisch.clear()
    const result = await fetch(`${config.server_address}/fe/app/txtFile/wisch.txt`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      data.message.forEach(art => {
        this.mapWisch.set(art.id, art.genart)
      })
    }
  }

  loadExcludeOELocksATFile = async() => {
    this.excludeOELocksAT = []
    const result = await fetch(`${config.server_address}/fe/app/xlsxFile/exclude-oe-lock-at.xlsx`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      data.message.forEach(man => {
        this.excludeOELocksAT.push(man)
      })
    }
  }
  
  searchInMappings = async(env, search, article) => {
    const mappingsTables =[]
    Object.entries(this.chShopsMapping).forEach(([key, value]) => {
      if (this.checked.includes(key) && !mappingsTables.includes(value.mappingTable)) {
        mappingsTables.push(value.mappingTable)
      }
    })
    for (const table of mappingsTables) {
      if (article !== undefined) {
        if (table === 'SAG_AxArticleData' && article.catalogitemnumber) {
          continue;
        } else if (table === 'ArticleMapping_FCAR' && article.fcar_articlenumber) {
          continue;
        } else if (table === 'ArticleMapping_WINT' && article.wint_articlenumber) {
          continue;
        } else if (table === 'ArticleMapping_Autonet' && article.autonet_itemnumber) {
          continue;
        }
      }
      if (table === 'SAG_AxArticleData' && isNaN(search)) {
        // don't search
      } else {
        const result = await fetch(`${config.server_address}/fe/servoArticles/mappings/${env}/${table}/${encodeURIComponent(search)}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.authStore.token}`,
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
        // token no longer valid => forward to welcome page
        if (result.status === 401) {
          this.authStore.clearUserSession(true)
          return
        }
        const data = await result.json()
        if (data.success) {
          data.message.forEach(article => {
            let cty
            const art = this.results.has(article.id_art) ? this.results.get(article.id_art).article : {}
            if (table === 'SAG_AxArticleData') {
              switch (article.ax_instance) {
                case '07SC':
                  cty = 'ch'
                  break;
                case '30DA':
                  cty = 'at'
                  break;
                case '40CZ':
                  cty = 'cz'
                  break;  
                case '51AM':
                  cty = 'hu'
                  break;  
                default:
                  break;
              }
              if (this.checked.includes(cty)) {
                art.catalogitemnumber = article.catalogitemnumber
                art.agrp = article.agrp
                art.manufactureritemnumber = article.manufactureritemnumber
                if (art.sales_stopped === undefined) {
                  art.sales_stopped = {}
                }
                art.sales_stopped[cty] = article.sales_stopped
                if (art.hasdefaultvendor === undefined) {
                  art.hasdefaultvendor = {}
                }
                art.hasdefaultvendor[cty] = article.hasdefaultvendor
                if (art.defaultvendor_stopped === undefined) {
                  art.defaultvendor_stopped = {}
                }
                art.defaultvendor_stopped[cty] = article.defaultvendor_stopped
                if (art.inventory === undefined) {
                  art.inventory = {}
                }
                art.inventory[cty] = article.inventory
              }
            } else if (table === 'ArticleMapping_FCAR') {
              cty = 'it'
              art.fcar_articlenumber = article.fcar_articlenumber
            } else if (table === 'ArticleMapping_WINT') {
              cty = 'sr'
              art.wint_articlenumber = article.wint_articlenumber
            } else if (table === 'ArticleMapping_Autonet') {
              cty = 'ro'
              art.autonet_itemnumber = article.autonet_itemnumber
            }
            if (this.results.has(article.id_art)) {
              const result = this.results.get(article.id_art)
              if (!result.cty.includes(cty) && this.checked.includes(cty)) {
                result.cty = result.cty + ',' + cty
              }
            } else {
              if (this.checked.includes(cty)) {
                this.results.set(article.id_art, { cty, article: art })
              }
            }  
          })
        }
      }
    }
  }
  /**
   * Get Articles from ES
   */
  serchArticles = async (env) => {
    this.servoItemsCount = ''
    this.results.clear()
    if (!this.searchText.trim()) {
      return 
    }
    await this.searchInMappings(env, this.searchText.trim())
    const art_ids = [...this.results.keys()]
    // add 9 digits id to find in Articles if it is not in mappings tables
    if (!isNaN(this.searchText.trim()) && this.searchText.trim().length === 9) {
      art_ids.push(this.searchText.trim())
    }
    const result = await fetch(`${config.server_address}/fe/servoArticles/articles`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        env,
        search: this.searchText.trim(),
        art_ids: art_ids.length > 0 ? art_ids.join(',') : undefined,
        searchByArtNr: this.searchByArtNr
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      const res = []
      for (const it of data.message) {
        await this.searchInMappings(env, it.id_art, this.results.has(it.id_art) ? this.results.get(it.id_art).article : undefined)
        it.manufacturer = this.appStore.mapManufacturers.get(it.id_manufacturer)?.name
        it.cty = this.results.has(it.id_art) ? this.results.get(it.id_art).cty : undefined
        it.article = this.results.has(it.id_art) ? this.results.get(it.id_art).article : undefined
        it.json_data = JSON.stringify(JSON.parse(it.json_data), undefined, '\t')
        res.push(it)
      }
      this.items = res
      this.servoItemsCount = this.items.length
    }
  }

  onSearchTextChanged (text) {
    this.searchText = text
  }

  onSearchByArtNrChecked (value) {
    this.searchByArtNr = value
  }
  
  readFreigabeTable = async(env,table,item,country) => {
    const resultFreigabe = await fetch(`${config.server_address}/fe/servoArticles/freigabe/${env}/${table}/${item.article.catalogitemnumber}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (resultFreigabe.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = (await resultFreigabe.json()).message
    let key = 'ch'
    switch (country) {
      case 'cz':
        key = 'cz'
        break;
      case 'hu':
        key = 'hu'
        break;
      default:
        key = 'ch'
        break;
    }
    if (res !== undefined) {
      item.freigabe[key] = true
      Object.keys(this.COUNTRY_TO_INSTANCES).forEach(cty => {
        this.COUNTRY_TO_INSTANCES[cty].forEach(instance => {
          if (res[`is_${cty}_vk`] === 0 && !item.locks_freigabe_vk.some(el => el.val === instance)) {
            item.locks_freigabe_vk.push({ val: instance, title: `from freigabe (${cty})`})
          }
          if (res[`is_${cty}_ku`] === 0 && !item.locks_freigabe_ku.some(el => el.val === instance)) {
            item.locks_freigabe_ku.push({ val: instance, title: `from freigabe (${cty})`})
          }
        })
      })
      if (country !== 'cz' && res.is_oe) {
        item.blockMsg_by_country[country.toUpperCase()] = 'article is OE'
      }
    } else {
      item.freigabe[key] = false
      item.blockMsg_by_country[country.toUpperCase()] = 'article not found in freigabe table'
    }
  }

  checkDataFromSettings = (settings, item, cty) => {
    if (settings.rules.ignore_genart_ids !== undefined && settings.rules.ignore_genart_ids.includes(Number(item.id_genart))) {
      item.blockMsg_by_country[cty.toUpperCase()] = `genart blocked for ${cty} in settings file`
    }
    if (settings.rules.genart_flip !== undefined) {
      settings.rules.genart_flip.forEach(genart => {
        if (genart.from.toString() === item.id_genart) {
          item.flip_genart_by_countries[cty] = { gaid: genart.to.toString(), reason: 'from settings file' }
        }
      })
    }
  }

  loadServoArticleDetails = async(env, item) => {
    this.mapServoGenarts.clear()
    const gaIds = []
    item.locks_freigabe_vk = []
    item.locks_freigabe_ku = []
    item.freigabe = {}
    item.flip_genart_by_countries = {}
    item.blockMsg_by_country = {}
    if(!item.id_genart) {
      item.id_genart = '15761'
    }
    item.isOeManufacturer = this.appStore.mapManufacturers.get(item.id_manufacturer)?.is_oemanufacturer
    if (item.article !== undefined) {
      // check in block table for ch and at
      if (item.article.catalogitemnumber !== undefined && (item.cty.includes('ch') || item.cty.includes('at'))) {
        const result = await fetch(`${config.server_address}/fe/servoArticles/block/${env}/${item.article.catalogitemnumber}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.authStore.token}`,
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
        // token no longer valid => forward to welcome page
        if (result.status === 401) {
          this.authStore.clearUserSession(true)
          return
        }
        const res = await result.json()
        if (res.message === true) {
          if (item.cty.includes('ch')) {
            item.blockMsg_by_country['CH'] = 'article blocked in block table'
          } 
          if (item.cty.includes('at')) {
            item.blockMsg_by_country['AT'] = 'article blocked in block table'
          }
        }
      }
      // check in ArticleExclusionDefinitions
      if (item.article.catalogitemnumber !== undefined) {
        const result = await fetch(`${config.server_address}/fe/servoArticles/locks/${env}/${item.article.catalogitemnumber}/${item.article.agrp}/${item.id_manufacturer}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.authStore.token}`,
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
        // token no longer valid => forward to welcome page
        if (result.status === 401) {
          this.authStore.clearUserSession(true)
          return
        }
        const dataLocks = (await result.json()).message
        const mandanten = []
        const notExcluded = []
        dataLocks.forEach(mandant => {
          if (mandant.sales_channel === 'ax-ch') {
            this.COUNTRY_TO_INSTANCES['ch'].forEach(instance => mandant.exclude === 0 ? notExcluded.push(instance) : !mandanten.includes(instance) && mandanten.push(instance))
          } else if (mandant.sales_channel === 'ax-at') {
            this.COUNTRY_TO_INSTANCES['at'].forEach(instance => mandant.exclude === 0 ? notExcluded.push(instance) : !mandanten.includes(instance) && mandanten.push(instance))
          } else {
            mandant.sales_channel.split(',').forEach(instance => mandant.exclude === 0 ? notExcluded.push(instance) : !mandanten.includes(instance) && mandanten.push(instance))
          }
        })
        mandanten.forEach(instance => {
          if (!notExcluded.includes(instance)) {
            item.locks_freigabe_vk.push({ val: instance, title: `from locks table`})
            item.locks_freigabe_ku.push({ val: instance, title: `from locks table`})
          }
        })
      }
      // read from freigabe
      let readCH = false
      for (const country of this.countries) {
        let table
        if (country === 'ch' || country === 'at') {
          if (!readCH) {
            readCH = true
            table = 'freigabe_ch'
            await this.readFreigabeTable(env,table,item,country)
            if (this.excludeOELocksAT.includes(Number(item.id_manufacturer)) && !item.freigabe[country]) {
              ['dat', 'mat','sag-gh-at'].forEach(instance => {
                if (!item.locks_freigabe_vk.some(el => el.val === instance)) {
                  item.locks_freigabe_vk.push({ val: instance, title: `manufacturer in exclude-oe-lock-at.xlsx`})
                }
                if (!item.locks_freigabe_ku.some(el => el.val === instance)) {
                  item.locks_freigabe_ku.push({ val: instance, title: `manufacturer in exclude-oe-lock-at.xlsx`})
                }
              })
            }
          }
        } else if (country === 'cz') {
          table = 'freigabe_cs'
          await this.readFreigabeTable(env,table,item,country)
        } else if (country === 'hu') {
          table = 'freigabe_hu'
          await this.readFreigabeTable(env,table,item,country)
        } else if (country === 'ro') {
          if (item.isOeManufacturer) {
            item.blockMsg_by_country[country.toUpperCase()] = 'article is OE'
          }
        }
      }
      
      if (item.article.catalogitemnumber !== undefined) {
        let data
        if (item.id_genart === '15761') {
          const result = await fetch(`${config.server_address}/fe/servoArticles/divers/${env}/${item.article.catalogitemnumber}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${this.authStore.token}`,
              'Content-Type': 'application/json; charset=utf-8'
            }
          })
          // token no longer valid => forward to welcome page
          if (result.status === 401) {
            this.authStore.clearUserSession(true)
            return
          }
          data = await result.json()
        } else {
          const result = await fetch(`${config.server_address}/fe/servoArticles/flip/${env}/${item.article.catalogitemnumber}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${this.authStore.token}`,
              'Content-Type': 'application/json; charset=utf-8'
            }
          })
          // token no longer valid => forward to welcome page
          if (result.status === 401) {
            this.authStore.clearUserSession(true)
            return
          }
          data = await result.json()
        }
        if (data.message.length > 0) {
          this.countries.forEach(cty => item.flip_genart_by_countries[cty] = { gaid: data.message[0].id_genart, reason: 'from flip_servo table'})
        }
        // flip genart from generic_articles from json for CZ
        if (this.countries.includes('cz') && JSON.parse(item.json_data).generic_articles !== undefined) {
          const genart = JSON.parse(item.json_data).generic_articles.findLast(ga => ga !== item.id_genart && this.universalPartsGenarts.includes(ga))
          if (genart !== undefined) {
            item.flip_genart_by_countries['cz'] = { gaid: genart, reason: 'is universal part' }
          }
        }    
        // flip genart from wisch.txt file
        if (this.mapWisch.has(item.article.catalogitemnumber)) {
          const ga = this.mapWisch.get(item.article.catalogitemnumber)
          this.countries.forEach(cty => {
            if (cty === 'ch' ||  cty === 'at' || cty === 'cz' || cty === 'hu') {
              item.flip_genart_by_countries[cty] = { gaid: ga, reason: 'from  wisch file' }
            }
          })
        }
      }
    }
    // flip to genart from settings if exists
    let readCH = false
    for (const country of this.countries) {
      if (country === 'ch' || country === 'at') {
        if (!readCH) {
          const settings = require(`../../config/settings/settings_ch.json`)
          this.checkDataFromSettings(settings, item, 'ch')
          readCH = true
        }
      } else {
        const settings = require(`../../config/settings/settings_${country}.json`)
        this.checkDataFromSettings(settings, item, country)
      }
    }
    // flip genart depending on SAG Article Groups (CI-100)
    const json = JSON.parse(item.json_data)
    if (json.group_data !== undefined && json.group_data.product_groups !== undefined) {
      json.group_data.product_groups.forEach(prod => {
        if (prod.level === '4') {
          if (prod.code === '6-1A1006' || prod.code === '6-1A1001') {
            item.flip_genart = '200424'
          } else if (prod.code === '1-110005') {
            item.flip_genart = '2110082'
          }
        } else if (prod.level === '3') {
          if (prod.code === '9-1A') {
            item.flip_genart = '200479'
          }
        }
      })
    }
    if (item.flip_genart !== undefined) {
      gaIds.push(item.flip_genart)
    }
    if (this.countries.includes('cz') && JSON.parse(item.json_data).generic_articles !== undefined) {
      const genart = JSON.parse(item.json_data).generic_articles.find(ga => ga !== item.id_genart && this.universalPartsGenarts.includes(ga))
      if (genart !== undefined) {
        item.flip_genart_by_countries['cz'] = { gaid: genart, reason: 'is universal part' }
      }
    } 
    // CI-225
    // flip genart from deviating_genart_assignments from json
    if (JSON.parse(item.json_data).deviating_genart_assignments !== undefined) {
      for (const country of this.countries) {
        const genart = JSON.parse(item.json_data).deviating_genart_assignments.findLast(dga => dga.catalog === shops[this.chShopsMapping[country].shop].name)?.id_genart
        if (genart !== undefined) {
          item.flip_genart_by_countries[country] = { gaid: genart, reason: 'from deviating_genart_assignments' }
        }
      }    
    }   

    gaIds.push(item.id_genart)
    Object.keys(item.flip_genart_by_countries).map(lang => (
      gaIds.push(item.flip_genart_by_countries[lang]?.gaid)
    ))
    JSON.parse(item.json_data).generic_articles?.map(ga => gaIds.push(ga))
    // load servo genarts
    const result = await fetch(`${config.server_address}/fe/servo/genericArticles/${env}/${this.defaultLang}/${gaIds.join(',')}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      res.message.forEach(ga => {
        this.mapServoGenarts.set(ga.id_genart, ga.deviating_text !== undefined ? ga.deviating_text : ga.text)
      })
    }
    // load textModules for info
    const idTextmodule = JSON.parse(item.json_data).info?.map(info => `'${info.id_textmodule}'`)
    if (idTextmodule !== undefined && idTextmodule.length > 0) {
      const resultInfo = await fetch(`${config.server_address}/fe/servo/textModules/${env}/${idTextmodule.join(',')}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      const resInfo = await resultInfo.json()
      if (resInfo.success === true) {
        resInfo.message.forEach(info => {
          this.mapTextModues.set(info.id_textmodule, info)
        })
      }
    }
    // sort ferigabe and locks
    // item.freigabe = item.freigabe.slice.sort((el1, el2) => el1.)
    item.locks_freigabe_vk = item.locks_freigabe_vk.slice().sort((lock1,lock2) => lock1.val.localeCompare(lock2.val))
    item.locks_freigabe_ku = item.locks_freigabe_ku.slice().sort((lock1,lock2) => lock1.val.localeCompare(lock2.val))
    this.item = item
  }

  loadESArticleDetails = async(env, item) => {
    this.itemEs = {}
    for (const country of this.countries) {
      let docId
      switch (country) {
        case 'ch':
        case 'at':
        case 'cz': 
        case 'hu': 
          docId = item.article.catalogitemnumber
          break;
        case 'it':  
          docId = item.article.fcar_articlenumber
          break;
        case 'sr':  
          docId = item.article.wint_articlenumber
          break;
        case 'ro':  
          docId = item.id_art
          break;
        default:
          break;
      } 
      if (docId !== undefined) {
        // get document
        const result = await this.getArticleDetailFromIndex(env, country, docId)
        if (Object.keys(result).length > 0) {
          // get genarts
          await this.fetchGenarts(env, country)
          this.itemEs[country.toUpperCase()] = {}
          Object.keys(result).forEach(lang => {
            const document = result[lang] !== undefined ? JSON.stringify(result[lang], undefined, '\t') : ''
            this.itemEs[country.toUpperCase()][lang] = document
          })
        }
      }
    }
  }
  
  loadArticleDetails = async(env, item) => {
    this.langsByCountry.clear()
    this.countries = item.cty !== undefined ? this.checked.filter(cty => item.cty.includes(cty)) : []
        this.countries.map(cty => this.langsByCountry.set(cty.toUpperCase(), shops[this.chShopsMapping[cty].shop].languages.split(',')))
    await Promise.all([this.loadServoArticleDetails(env, item), this.loadESArticleDetails(env, item)])
    if (this.countries.length > 0) {
      this.populateDetailItems(this.countries[0].toUpperCase(), this.langsByCountry.get(this.countries[0].toUpperCase())[0])
    } else {
      this.populateDetailItems()
    }
  }

  getArticleDetailFromIndex = async(env,cty,id) => {
    const index_name = shops[this.chShopsMapping[cty].shop].indexPrefix + 'articles'
    const result = await fetch(`${config.server_address}/fe/es/getDocument/${env}/${shops[this.chShopsMapping[cty].shop].esServer}/${index_name}/refs/${encodeURIComponent(id)}/${shops[this.chShopsMapping[cty].shop].languages}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
          this.authStore.clearUserSession(true)
          return
      }
      const data = await result.json()
      if (data.success) {
        return data.message
      }
      return {}
  }

  populateDetailItems = (country, lang) => {
        this.detailsItems = []
    const json = country!== undefined && lang !== undefined && this.itemEs[country] !== undefined && this.itemEs[country][lang] !== undefined ? JSON.parse(this.itemEs[country][lang]) : undefined
    // date of last update
    this.detailsItems.push({
      detail: 'Date of last update', 
      servo: this.item.date_updated !== undefined ? new Date(this.item.date_updated).toISOString().slice(0, 19).replace('T', ' ') : '',
      es: json !== undefined && json.create !== undefined ? new Date(json.create).toISOString().slice(0, 19).replace('T', ' ') : (country !== undefined ? this.item.blockMsg_by_country[country] : 'article not in mapping tables')
    })
    // article number
    this.detailsItems.push({
      detail: 'Article number', 
      servo: this.item.art_nr,
      es: json !== undefined ? json.artnr_display : ''
    })
    // ERP Id
    let servoErpId = []
    if (this.item.article !== undefined) {
      if (this.item.article.catalogitemnumber !== undefined) {
        servoErpId.push(this.item.article.catalogitemnumber + ' (AxID)')
      }
      if (this.item.article.fcar_articlenumber !== undefined) {
        servoErpId.push(this.item.article.fcar_articlenumber + ' (FCAR)')
      }
      if (this.item.article.wint_articlenumber !== undefined) {
        servoErpId.push(this.item.article.wint_articlenumber + ' (WINT)')
      }
      if (this.item.article.autonet_itemnumber !== undefined) {
        servoErpId.push(this.item.article.autonet_itemnumber + ' (AUTONET)')
      }
    } 
    this.detailsItems.push({
      detail: 'ERP ID', 
      servo: servoErpId,
      es: json !== undefined && (country !== 'RO' ? json.artid : json.id_autonet)
    })
    // Manufacturer
    this.detailsItems.push({
      detail: 'Manufacturer', 
      servo: this.item.manufacturer,
      es: json !== undefined && json.id_dlnr !== undefined ? this.appStore.mapManufacturers.get(json.id_dlnr.toString())?.name : ''
    })
    
    const prodDesc = []
    let name = JSON.parse(this.item.json_data).designation_short.find(ds => ds.language === this.defaultLang)?.value
    if (name === undefined && this.mapServoGenarts.get(this.item.id_genart) !== undefined) {
      name = this.mapServoGenarts.get(this.item.id_genart)
    } 
    name !== undefined && prodDesc.push(name)
    
    const ad = JSON.parse(this.item.json_data).additional_description.find(ad => ad.language === this.defaultLang)?.value
    ad !== undefined && ad !== name && prodDesc.push(ad)
    const ed = JSON.parse(this.item.json_data).extended_description.find(ed => ed.language === this.defaultLang)?.value
    ed !== undefined && ed !== name && prodDesc.push(ed)
    if (ed === undefined) {
      const dl = JSON.parse(this.item.json_data).designation_long.find(dl => dl.language === this.defaultLang)?.value
      dl !== undefined && dl !== name && prodDesc.push(dl)
    }
    // Product description
    this.detailsItems.push({
      detail: 'Product description', 
      servo: prodDesc.join(' - '),
      es: json !== undefined ? json.name + (json.product_addon ? ' - ' + json.product_addon : '') : ''
    })
    // Genart ID
    this.detailsItems.push({
      detail: 'Genart ID', 
      servo: this.item.id_genart + (this.mapServoGenarts.get(this.item.id_genart) ? '-' + this.mapServoGenarts.get(this.item.id_genart) : ''),
      es: json !== undefined && json.gaID !== undefined ? json.gaID + '-' + this.mapGenarts.get(lang)?.get(json.gaID.toString()) : ''
    })
    // Flip genart
    if(Object.keys(this.item.flip_genart_by_countries).length > 0) {
      Object.keys(this.item.flip_genart_by_countries).map(lang => (
        this.detailsItems.push({
          detail: `Flip Genart ID to (${lang.toUpperCase()})`, 
          servo: this.item.flip_genart_by_countries[lang]?.gaid + (this.mapServoGenarts.get(this.item.flip_genart_by_countries[lang]?.gaid) ? '-' + this.mapServoGenarts.get(this.item.flip_genart_by_countries[lang]?.gaid) : '') + ' ('+ this.item.flip_genart_by_countries[lang]?.reason +')',
          es: ''
        })
      ))
    }
    if (Object.keys(this.item.flip_genart_by_countries).length === 0 && this.item.flip_genart !== undefined) {
      console.log(this.item.flip_genart, this.mapServoGenarts.get(this.item.flip_genart))
      this.detailsItems.push({
        detail: 'Flip Genart ID to', 
        servo: this.item.flip_genart + (this.mapServoGenarts.get(this.item.flip_genart) ? '-' + this.mapServoGenarts.get(this.item.flip_genart) : '') + ' (SAG Article Group rules)',
        es: ''
      })
    } 
    const otherGenarts = []
    JSON.parse(this.item.json_data).generic_articles?.map(ga => otherGenarts.push(ga + (this.mapServoGenarts.get(ga) ? '-' + this.mapServoGenarts.get(ga) : '')))
    // other genarts
    this.detailsItems.push({
      detail: 'Other genarts', 
      servo: otherGenarts,
      es: ''
    })
    // article group
    this.detailsItems.push({
      detail: 'Article group', 
      servo: this.item.article !== undefined ? this.item.article.agrp : '',
      es: json !== undefined ? (json.sag_product_group_4 !== null ? json.sag_product_group_4 : 
        json.sag_product_group_3 !== null ? json.sag_product_group_3 : 
        json.sag_product_group_2 !== null ? json.sag_product_group_2 : json.sag_product_group) :''
    })
    // ferigabe
    if (this.item.freigabe !== undefined && Object.keys(this.item.freigabe).length > 0) {
      Object.keys(this.item.freigabe).map(cty => (
        this.detailsItems.push({
          detail: 'Freigabe ' + cty.toUpperCase(), 
          servo: this.item.freigabe[cty] ? 'Yes' : 'No',
          es: this.item.freigabe[cty] === false && (cty.toUpperCase() === country || (cty === 'ch' && country === 'AT') ) ? ' ' : ''
        })
      ))
    }
    // Locks vk
    this.detailsItems.push({
      detail: 'Locks Vk', 
      servo: this.item.locks_freigabe_vk.length > 0 ? this.item.locks_freigabe_vk.map(lock => lock.val).join(' ') : '',
      es: json !== undefined && json.locks_vk?.split(',').sort((l1,l2) => l1.localeCompare(l2)).join(' ')
    })
    // Locks ku
    this.detailsItems.push({
      detail: 'Locks Ku', 
      servo: this.item.locks_freigabe_ku.length > 0 ? this.item.locks_freigabe_ku.map(lock => lock.val).join(' ') : '',
      es: json !== undefined && json.locks_ku?.split(',').sort((l1,l2) => l1.localeCompare(l2)).join(' ')
    })
    // sales stopped
    this.detailsItems.push({
      detail: 'Sales stopped', 
      servo: this.item.article !== undefined && this.item.article.sales_stopped !== undefined && Object.keys(this.item.article.sales_stopped).length > 0 ? Object.keys(this.item.article.sales_stopped).map(cty => (this.item.article.sales_stopped[cty] ? 'Yes' : 'No') + ' (' + cty.toUpperCase() + ')').join(', ') : '',
      es: ''
    })
    // has default vendor 
    this.detailsItems.push({
      detail: 'Has default vendor',
      servo: this.item.article !== undefined && this.item.article.hasdefaultvendor !== undefined && Object.keys(this.item.article.hasdefaultvendor).length > 0 ? Object.keys(this.item.article.hasdefaultvendor).map(cty => (this.item.article.hasdefaultvendor[cty] ? 'Yes' : 'No') + ' (' + cty.toUpperCase() + ')').join(', ') : '',
      es: ''
    })
    // default vendor stopped
    this.detailsItems.push({
      detail: 'Default vendor stopped', 
      servo: this.item.article !== undefined && this.item.article.defaultvendor_stopped !== undefined && Object.keys(this.item.article.defaultvendor_stopped).length > 0 ? Object.keys(this.item.article.defaultvendor_stopped).map(cty => (this.item.article.defaultvendor_stopped[cty] ? 'Yes' : 'No') + ' (' + cty.toUpperCase() + ')').join(', ') : '',
      es: ''
    })    
    // inventory
    this.detailsItems.push({
      detail: 'Inventory', 
      servo: this.item.article !== undefined && this.item.article.inventory !== undefined && Object.keys(this.item.article.inventory).length > 0 ? Object.keys(this.item.article.inventory).filter(cty => this.item.article.inventory[cty] !== null).map(cty => this.item.article.inventory[cty] + ' (' + cty.toUpperCase() + ')').join(', ') : '',
      es: ''
    })
    const servoDocuments = []
    JSON.parse(this.item.json_data).documents?.forEach(doc => 
      doc.values.forEach(val => servoDocuments.push(val))
    )
    // documents
    this.detailsItems.push({
      detail: 'Documents', 
      servo: servoDocuments,
      es: json !== undefined ? json.images.filter(img => img.img_typ !== 'image_300').sort((img1,img2) => img1.id_img - img2.id_img) : ''
    })
    // info
    const servoInfo = []
    JSON.parse(this.item.json_data).info?.forEach(info => {
      const textModule = this.mapTextModues.get(info.id_textmodule)
      if (textModule !== undefined) {
        const text = JSON.parse(textModule.json_data)?.text?.find(den => den.language === lang)?.value
        text !== undefined && servoInfo.push({id_textmodule: info.id_textmodule, text})
      }
    })
    this.detailsItems.push({
      detail: 'Info', 
      servo: servoInfo,
      es: json !== undefined ? json.info.sort((info1,info2) => info1.sort - info2.sort) : ''
    })
  }
}

export default ArticleStore