// import libs
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import FlagIconFactory from 'react-flag-icon-css'

// import fabric-ui
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'

// import components

// import styles

// eslint-disable-next-line new-cap
const FlagIcon = FlagIconFactory(React, { useCssModules: false })

@inject('store')
@observer
class LanguageSwitcher extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false,
      target: null
    }

    this.possibleLanguages = [
      {
        key: 'de',
        onClick: () => this.setMainLanguage('de'),
        name: 'Deutsch',
        title: 'Deutsch'
      },
      {
        key: 'en',
        onClick: () => this.setMainLanguage('en'),
        name: 'English',
        title: 'English'
      },
      {
        key: 'it',
        onClick: () => this.setMainLanguage('it'),
        name: 'Italian',
        title: 'Italian'
      },
      {
        key: 'fr',
        onClick: () => this.setMainLanguage('fr'),
        name: 'French',
        title: 'French'
      },
      {
        key: 'cs',
        onClick: () => this.setMainLanguage('cs'),
        name: 'Czech',
        title: 'Czech'
      },
      {
        key: 'sr',
        onClick: () => this.setMainLanguage('sr'),
        name: 'Serbian',
        title: 'Serbian'
      },
      {
        key: 'hu',
        onClick: () => this.setMainLanguage('hu'),
        name: 'Hungarian',
        title: 'Hungarian'
      }
    ]
  }

  setMainLanguage = lng => this.props.store.appStateStore.set_gui_language(lng)
  handleToggleMenu = event => this.props.store.authStore.selectedApp === null || 
    (this.props.store.authStore.selectedApp === 'unitree' && this.props.store.unitreeStore.selectedUnitree === null) || 
    (this.props.store.authStore.selectedApp === 'categories' && this.props.store.categoriesStore.selectedESObject === null) ||
    (this.props.store.authStore.selectedApp === 'brand_priority' && this.props.store.brandPriorityStore.selectedESObject === null) ? 
    this.setState({ target: event.target, showMenu: !this.state.showMenu }) : ''

  render () {
    // beacause uk flag is gb and cs flag is cz and sr flaf is rs
    let _lngCode
    switch (this.props._lng) {
      case 'en':
        _lngCode = 'gb'
        break;
      case 'cs':
        _lngCode = 'cz'
        break;
      case 'sr':
        _lngCode = 'rs'
        break;
      default:
        _lngCode = this.props._lng
        break;
    }
    return (
      <div className='current--language'>

        <div onClick={this.handleToggleMenu}>
          <FlagIcon code={_lngCode} />
          <p>{this.possibleLanguages.find(pLang => pLang.key === this.props._lng).title || ''}</p>
        </div>

        {
          this.state.showMenu &&
            <ContextualMenu
              target={this.state.target}
              onDismiss={this.handleToggleMenu}
              items={this.possibleLanguages}
            />
        }
      </div>
    )
  }
}

export default LanguageSwitcher
